import React from 'react';

import './spinner.styles.css';

const WithSpinner = () => {
return (
<div className="main">
<div className="one"></div>
<div className="two"></div>
<div className="three"></div>
</div>
);
 
};

export default WithSpinner;



